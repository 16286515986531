@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Linefont:wght@300&display=swap');

:root {
  --text: white;
  --accent: #FD4D96;
  --background: #3E285D;
}

/* width */
::-webkit-scrollbar{
  width: 1em;
}

/* track */
::-webkit-scrollbar-track{
  background: black;
}

/* handle */
::-webkit-scrollbar-thumb{
  background: var(--background);
  border-radius: 20px;
  border: 3px solid var(--background);
}

/* handle on hover */
::-webkit-scrollbar-thumb:hover{
  background: #593C82;
  border: 3px solid #593C82;
  cursor: pointer;
}

body {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  color: var(--text);
  background-image: url('./assets/galaxy.gif');
}

.App{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container{
  background-color: var(--background);
  width: 60%;
  min-height: 80vh;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: first baseline;
}

a{
  color: var(--accent);
}

a:hover{
  color: var(--background);
  background-color: white;
}

h1{
  margin: 0px;
}

h2{
  margin: 0px;
}

h3{
  margin: 0px;
}

@media (max-width: 1100px){
  .container{
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .container{
    width: 80%;
  }
}