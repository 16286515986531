.nav{
    background-color: var(--background);
    width: 60%;
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navStar{
    width: 30px;
    padding: 5px;
}

.navItems{
    width: 100%;
    list-style-type: none;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.nav a{
    color: var(--text);
}

.nav a:hover{
    color: var(--background);
    background-color: white;
}

@media (max-width: 1100px){
    .nav{
      width: 70%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .nav{
      width: 80%;
    }
  }