.blogPreview{
	display: flex;
	flex-direction: row;
	align-items: first baseline;
    margin: 5px 5px 25px 20px;
}

.envelopeIcon{
    transform: scale(1.75);
    margin-right: 20px;
}

.blogPreview h3{
    font-weight: normal;
    margin-bottom: 5px;
}

.blogContainer{
    margin: 10px;
}

.blogContainer h2{
    font-weight: normal;
    margin-bottom: 5px;
}

.blogTags{
    margin-bottom: 15px;
}

.blogTags ul{
    display: inline;
    list-style-type: none;
    padding: 0px;
}

.blogTags li{
    margin: 5px;
    display: inline;
}

.blogFooterLinks{
    margin: 0px;
    width: 97%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}